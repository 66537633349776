import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import TideTimesWidget from './TideTimesWidget';
import WeatherWidget from './WeatherWidget';
import '../styles/Sidebar.css';

const Sidebar = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <aside className="sidebar">
      <div className="widget">
        <TideTimesWidget />
      </div>
      <div className="widget">
        <WeatherWidget />
      </div>
      <div className="widget">
        <h2>Administrasjon</h2>
        {user ? (
          <div>
            <p>Hei {user.displayName}. Du er logget inn.</p>
            <Button variant="outlined" color="secondary" onClick={logout}>
              Logge ut
            </Button>
          </div>
        ) : (
          <Link to="/login">Logg inn</Link>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
